import Gift8 from "./assets/image/SelectorImage/strawberry.png";
import Gift7 from "./assets/image/SelectorImage/banana.png";
import Gift6 from "./assets/image/SelectorImage/orange.png";
import Gift5 from "./assets/image/SelectorImage/cherry.png";
import Gift4 from "./assets/image/SelectorImage/half-chicken.png";
import Gift3 from "./assets/image/SelectorImage/fish.png";
import Gift2 from "./assets/image/SelectorImage/beaf.png";
import Gift1 from "./assets/image/SelectorImage/burger.png";

import coin1 from "../src/assets/image/c1.png";
import coin2 from "../src/assets/image/c2.png";
import coin3 from "../src/assets/image/c3.png";
import coin4 from "../src/assets/image/c4.png";

export const imageShowData = [Gift1, Gift2, Gift3, Gift4, Gift5, Gift6, Gift7, Gift8];

export const coinShowData = [coin1, coin2, coin3, coin4];
