import React from "react";
import DimondIcon from "../assets/image/dimondIcon.png";

export default function SelectCricle(props) {
  const {
    styles,
    image,
    lineCricleStlye,
    cricleMainStlye,
    onClick,
    framNo,
    selectFrame,
    timeText,
  } = props;


  return (
    <div
      className="cricle-wheel"
      style={{
        left: cricleMainStlye?.left,
        top: cricleMainStlye?.top,
        bottom: cricleMainStlye?.bottom,
        transform: cricleMainStlye?.transform,
        right: cricleMainStlye?.right,
      }}
      onClick={onClick}
    >
      <img src={image} style={{position: "relative"}} />
      <div class="gray"></div>
      <div class="winnerFram"></div>
      {(selectFrame?.Bit > 0 ? selectFrame?.selectFrame === framNo : "") && (
        // <div className="selected-fram">
          <div className="bet-details" style={{position: "absolute", bottom: "0px"}}>
            {/* <img src={DimondIcon} /> */}
            <h6 style={{fontWeight: "800", fontSize: "12px", marginBottom: "5px"}}>{selectFrame?.Bit}</h6>
          </div>
        // </div>
      )}
    </div>
  );
}
