import React from 'react'

const Options = () => {
  return (
    <svg style={{backgroundColor: "transparent"}} width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_36_6867)">
<path d="M49.4276 32.074C46.6805 43.8833 34.8677 51.1611 23.0549 48.4148C11.2421 45.6684 3.82482 33.8592 6.57198 22.0499C9.31914 10.2406 21.1319 2.82547 33.0821 5.57181C44.7575 8.31816 52.1748 20.1274 49.4276 32.074Z" fill="#FFD475"/>
<path d="M49.4276 32.0783C45.3068 49.9325 22.3681 54.8767 11.1047 41.0054C24.5 53.9934 44 47.9942 48 28.9968C49.2841 21.4978 46.131 15.1856 42.0102 10.104C48.1914 15.0483 51.3506 23.5633 49.4276 32.0783Z" fill="#F7C166"/>
<path d="M47 26.997C47 37.4784 38.4828 45.9945 28 45.9945C17.5172 45.9945 9 37.4784 9 26.997C9 16.5157 17.5172 7.9996 28 7.9996C38.4828 8.16337 47 16.5157 47 26.997Z" fill="#F3A250"/>
<path d="M45.1983 26.997C45.1983 36.4957 37.5 44.193 28 44.193C18.5 44.193 10.8017 36.4957 10.8017 26.997C10.8017 17.4983 18.5 9.96481 28 9.96481C37.5 9.96481 45.1983 17.4983 45.1983 26.997Z" fill="#DD7F2F"/>
<path d="M45.0345 28.1434C44.5431 19.136 37.1724 12.0938 28 12.0938C18.8276 12.0938 11.4569 19.136 10.8017 28.1434C10.3103 17.6621 18.5 9.96481 28 9.96481C37.5 9.96481 45.6896 17.6621 45.0345 28.1434Z" fill="#BB6720"/>
</g>
<path d="M35.6562 19H24.4062C24.0333 19 23.6756 19.1482 23.4119 19.4119C23.1482 19.6756 23 20.0333 23 20.4062C23 20.7792 23.1482 21.1369 23.4119 21.4006C23.6756 21.6643 24.0333 21.8125 24.4062 21.8125H35.6562C36.0292 21.8125 36.3869 21.6643 36.6506 21.4006C36.9143 21.1369 37.0625 20.7792 37.0625 20.4062C37.0625 20.0333 36.9143 19.6756 36.6506 19.4119C36.3869 19.1482 36.0292 19 35.6562 19Z" fill="#BD6011"/>
<path d="M19.4062 21.8125C20.1829 21.8125 20.8125 21.1829 20.8125 20.4062C20.8125 19.6296 20.1829 19 19.4062 19C18.6296 19 18 19.6296 18 20.4062C18 21.1829 18.6296 21.8125 19.4062 21.8125Z" fill="#BD6011"/>
<path d="M35.6562 25.0938H24.4062C24.0333 25.0938 23.6756 25.2419 23.4119 25.5056C23.1482 25.7694 23 26.127 23 26.5C23 26.873 23.1482 27.2306 23.4119 27.4944C23.6756 27.7581 24.0333 27.9062 24.4062 27.9062H35.6562C36.0292 27.9062 36.3869 27.7581 36.6506 27.4944C36.9143 27.2306 37.0625 26.873 37.0625 26.5C37.0625 26.127 36.9143 25.7694 36.6506 25.5056C36.3869 25.2419 36.0292 25.0938 35.6562 25.0938Z" fill="#BD6011"/>
<path d="M19.4062 27.9062C20.1829 27.9062 20.8125 27.2766 20.8125 26.5C20.8125 25.7233 20.1829 25.0938 19.4062 25.0938C18.6296 25.0938 18 25.7233 18 26.5C18 27.2766 18.6296 27.9062 19.4062 27.9062Z" fill="#BD6011"/>
<path d="M35.6562 31.1875H24.4062C24.0333 31.1875 23.6756 31.3357 23.4119 31.5994C23.1482 31.8631 23 32.2208 23 32.5937C23 32.9667 23.1482 33.3244 23.4119 33.5881C23.6756 33.8518 24.0333 34 24.4062 34H35.6562C36.0292 34 36.3869 33.8518 36.6506 33.5881C36.9143 33.3244 37.0625 32.9667 37.0625 32.5937C37.0625 32.2208 36.9143 31.8631 36.6506 31.5994C36.3869 31.3357 36.0292 31.1875 35.6562 31.1875Z" fill="#BD6011"/>
<path d="M19.4062 34C20.1829 34 20.8125 33.3704 20.8125 32.5937C20.8125 31.8171 20.1829 31.1875 19.4062 31.1875C18.6296 31.1875 18 31.8171 18 32.5937C18 33.3704 18.6296 34 19.4062 34Z" fill="#BD6011"/>
<path d="M35.6721 20H25.0492C24.697 20 24.3593 20.1399 24.1102 20.3889C23.8612 20.6379 23.7213 20.9757 23.7213 21.3279C23.7213 21.68 23.8612 22.0178 24.1102 22.2668C24.3593 22.5158 24.697 22.6557 25.0492 22.6557H35.6721C36.0243 22.6557 36.3621 22.5158 36.6111 22.2668C36.8601 22.0178 37 21.68 37 21.3279C37 20.9757 36.8601 20.6379 36.6111 20.3889C36.3621 20.1399 36.0243 20 35.6721 20Z" fill="white"/>
<path d="M20.3279 22.6557C21.0612 22.6557 21.6557 22.0612 21.6557 21.3279C21.6557 20.5945 21.0612 20 20.3279 20C19.5945 20 19 20.5945 19 21.3279C19 22.0612 19.5945 22.6557 20.3279 22.6557Z" fill="white"/>
<path d="M35.6721 25.7541H25.0492C24.697 25.7541 24.3593 25.894 24.1102 26.143C23.8612 26.3921 23.7213 26.7298 23.7213 27.082C23.7213 27.4342 23.8612 27.7719 24.1102 28.0209C24.3593 28.27 24.697 28.4099 25.0492 28.4099H35.6721C36.0243 28.4099 36.3621 28.27 36.6111 28.0209C36.8601 27.7719 37 27.4342 37 27.082C37 26.7298 36.8601 26.3921 36.6111 26.143C36.3621 25.894 36.0243 25.7541 35.6721 25.7541Z" fill="white"/>
<path d="M20.3279 28.4099C21.0612 28.4099 21.6557 27.8154 21.6557 27.082C21.6557 26.3486 21.0612 25.7541 20.3279 25.7541C19.5945 25.7541 19 26.3486 19 27.082C19 27.8154 19.5945 28.4099 20.3279 28.4099Z" fill="white"/>
<path d="M35.6721 31.5082H25.0492C24.697 31.5082 24.3593 31.6481 24.1102 31.8971C23.8612 32.1462 23.7213 32.4839 23.7213 32.8361C23.7213 33.1883 23.8612 33.526 24.1102 33.775C24.3593 34.024 24.697 34.1639 25.0492 34.1639H35.6721C36.0243 34.1639 36.3621 34.024 36.6111 33.775C36.8601 33.526 37 33.1883 37 32.8361C37 32.4839 36.8601 32.1462 36.6111 31.8971C36.3621 31.6481 36.0243 31.5082 35.6721 31.5082Z" fill="white"/>
<path d="M20.3279 34.1639C21.0612 34.1639 21.6557 33.5694 21.6557 32.8361C21.6557 32.1027 21.0612 31.5082 20.3279 31.5082C19.5945 31.5082 19 32.1027 19 32.8361C19 33.5694 19.5945 34.1639 20.3279 34.1639Z" fill="white"/>
<defs>
<filter id="filter0_d_36_6867" x="0" y="0" width="56" height="56" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="3"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_36_6867"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_36_6867" result="shape"/>
</filter>
</defs>
</svg>

  )
}

export default Options