import React, { useEffect, useRef, useState } from "react";
import SelectCricle from "./SelectCricle";
import DimondIcon from "../assets/image/dimondIcon.png";
import HandIcon from "../assets/image/head.png";
import RecordsDialog from "../Dialog/RecordsDialog";
import RulesDialog from "../Dialog/RulesDialog";
import CoinButton from "./CoinButton";
import { ToastConent } from "../ToastConent";
import WinnerDialog from "../Dialog/WinnerDialog";
import { coinShowData, imageShowData } from "../GiftImage";
import Options from "../assets/icons/Options";
import Winner from "../assets/icons/Winner";
import Rupee from "../assets/icons/Rupee";
import Plus from "../assets/icons/Plus";
import resultbg from "../assets/image/resultImage.png";
import roundbg from "../assets/image/roundbg.png";
import round from "../assets/image/round.png";

const handClasses = [
  "hand1",
  "hand2",
  "hand3",
  "hand4",
  "hand5",
  "hand6",
  "hand7",
  "hand8",
];
let selectedCoin = 0;
let selectedFram = [
  { selectFrame: 1, Bit: 0 },
  { selectFrame: 2, Bit: 0 },
  { selectFrame: 3, Bit: 0 },
  { selectFrame: 4, Bit: 0 },
  { selectFrame: 5, Bit: 0 },
  { selectFrame: 6, Bit: 0 },
  { selectFrame: 7, Bit: 0 },
  { selectFrame: 8, Bit: 0 },
];
let totalMineBetCoin = 0;
let winnerFram = 0;
let todayProfit = 0;
let historiesShow = [];
let gameRound = 0;
let gameRoundWinnerShow = 0;
let winnerShow = [];
let historyRecord = [];
let currentGame = [];
const queryParams = new URLSearchParams(window.location.search);
const userId = queryParams.get("id");
export default function WheelContent(props) {
  const { userData, socket, gameCoin, settingData } = props;
  const [seconds, setSeconds] = useState(0);
  const [roundCounter, setRoundCounter] = useState(0);
  const [rollCount, setRollCount] = useState(0);
  const [handClassIndex, setHandClassIndex] = useState(0);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [recordsDialogOpen, setRecordsDialogOpen] = useState(false);
  const [rulesDialogOpen, setRulesDialogOpen] = useState(false);
  const [winnerModelOpen, setWinnerModelOpen] = useState(false);
  const [time, setTime] = useState();
  const [canPressCoins, setCanPressCoins] = useState(true);
  const [timerText, setTimerText] = useState("0s");
  const [showCricle, setShowCricle] = useState(false);
  const [showHandImage, setShowHandImage] = useState(false);

  useEffect(() => {
    socket?.on("time", (time) => {
      updateTime(time);
      setTime(time);
    });

    socket?.on("game", (game) => {
      currentGame = game?.UsersBits;
      if (game) {
        selectedFram = selectedFram = [
          { selectFrame: 1, Bit: 0 },
          { selectFrame: 2, Bit: 0 },
          { selectFrame: 3, Bit: 0 },
          { selectFrame: 4, Bit: 0 },
          { selectFrame: 5, Bit: 0 },
          { selectFrame: 6, Bit: 0 },
          { selectFrame: 7, Bit: 0 },
          { selectFrame: 8, Bit: 0 },
        ];
        updateGame(game);
      }
    });
    setTimeout(() => {
      socket?.on("randomWinnerNumber", (winner) => {
        winnerFram = winner;
      });
    }, 3000);

    setTimeout(() => {
      socket?.on("winnerUserArray", (winnerUser) => {
        winnerShow = winnerUser;
      });
    }, 3000);

    socket?.on("gameRound", (gameRoundNo) => {
      gameRound = gameRoundNo;
    });

    socket?.on("todayProfit", (profit) => {
      todayProfit = profit;
    });

    socket?.on("lastHistories", (histories) => {
      historiesShow = histories;
    });
  }, [socket]);

  socket?.on("historyRecord", (historyRecordData) => {
    historyRecord = historyRecordData;
  });
  const intervalId = () => {
    const randomNumber = Math.floor(Math.random() * (900 - 700) + 700);
    const firstIntervalId = setInterval(() => {
      if (seconds < 10) {
        setRoundCounter((prevRoundCounter) => (prevRoundCounter % 7) + 1);
      }
      if (seconds >= 10) {
        clearInterval(firstIntervalId);
      }
    }, randomNumber);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds + 1);
      setElapsedTime((prevElapsedTime) => prevElapsedTime + 1000);

      if (seconds === 15) {
        setSeconds(0);
        setRoundCounter(-1);
        setElapsedTime(0);
        intervalId();
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  useEffect(() => {
    if (showCricle) {
      const totalGrayElements = document.querySelectorAll(
        ".cricle-wheel .gray"
      ).length;

      const rollTimer = setInterval(() => {
        document
          .querySelectorAll(".cricle-wheel .gray")
          .forEach((grayItem, index) => {
            if (grayItem) {
              grayItem.style.display = index === rollCount ? "none" : "block";
            }
          });
        setRollCount((prevRollCount) =>
          prevRollCount >= totalGrayElements - 1 ? 0 : prevRollCount + 1
        );
      }, 50);
      return () => clearInterval(rollTimer);
    }
    if (showHandImage) {
      const totalGrayElements = document.querySelectorAll(
        ".cricle-wheel .gray"
      ).length;

      const rollTimer = setInterval(() => {
        document
          .querySelectorAll(".cricle-wheel .gray")
          .forEach((grayItem, index) => {
            if (grayItem) {
              grayItem.style.display = "none";
            }
          });

        setRollCount((prevRollCount) =>
          prevRollCount >= totalGrayElements - 1 ? 0 : prevRollCount + 1
        );
        if (elapsedTime >= 1500) {
          setElapsedTime(0);
          setHandClassIndex(
            (prevIndex) => (prevIndex + 1) % handClasses.length
          );
        }
      }, 400);
      return () => clearInterval(rollTimer);
    }
    if (showHandImage === false && showCricle === false) {
      document
        .querySelectorAll(".cricle-wheel .gray")
        .forEach((grayItem, index) => {
          if (grayItem) {
            grayItem.style.display = "none";
          }
        });
    }
  }, [rollCount, elapsedTime]);

  const handleOpenModel = (type) => {
    if (type === "records") {
      setRecordsDialogOpen(true);
      setRulesDialogOpen(false);
      socket?.off("historyRecord")?.emit("historyRecord", {
        _id: userData?._id,
      });
    } else {
      setRulesDialogOpen(true);
      setRecordsDialogOpen(false);
    }
  };

  const updateTime = (time) => {
    if (time === 25) {
      selectedCoin = gameCoin[0];
    }
    if (time >= 4) {
      setCanPressCoins(true);
      setTimerText(time + "s");
    } else if (time >= 0 && time <= 3) {
      setCanPressCoins(false);
      setTimerText(time + "s");
    } else if (time <= 0 && time > -12) {
      selectedFram = [
        { selectFrame: 1, Bit: 0 },
        { selectFrame: 2, Bit: 0 },
        { selectFrame: 3, Bit: 0 },
        { selectFrame: 4, Bit: 0 },
        { selectFrame: 5, Bit: 0 },
        { selectFrame: 6, Bit: 0 },
        { selectFrame: 7, Bit: 0 },
        { selectFrame: 8, Bit: 0 },
      ];
      selectedCoin = -1;
      setCanPressCoins(false);
      setTimerText(11 + time + "s");
    }

    if (time >= 1 && time <= 24) {
      setShowHandImage(true);
      setShowCricle(false);
    }
    if (time <= 0 && time >= -12) {
      setShowHandImage(false);
      setShowCricle(true);
    }
    if (time === -12) {
      setShowHandImage(false);
      setShowCricle(false);
    }

    if (time === -12) {
      winnerFramFunction("show");
      setTimeout(() => {
        gameRoundWinnerShow = gameRound;
        setWinnerModelOpen(true);
      }, 500);
    }

    if (time === 22) {
      setWinnerModelOpen(false);
      gameRoundWinnerShow = gameRound;
      winnerFramFunction("close");
    }

    if (time === -13) {
      socket?.emit("user", {
        _id: userData?._id,
      });
    }
  };

  const winnerFramFunction = (type) => {
    if (type === "show") {
      document
        .querySelectorAll(".cricle-wheel .winnerFram")
        .forEach((grayItem, index) => {
          if (grayItem) {
            grayItem.style.display =
              index + 1 === winnerFram ? "block" : "none";
            const detailsWin =
              grayItem.parentElement.querySelector(".details-win");
            if (detailsWin) {
              if (index + 1 === winnerFram) {
                detailsWin.classList.add("winner-addText");
              } else {
                detailsWin.classList.remove("winner-addText");
              }
            }
          }
        });
    } else {
      document
        .querySelectorAll(".cricle-wheel .winnerFram")
        .forEach((grayItem, index) => {
          if (grayItem) {
            grayItem.style.display = "none";
            const detailsWin =
              grayItem.parentElement.querySelector(".details-win");
            if (detailsWin) {
              detailsWin.classList.remove("winner-addText");
            }
          }
        });
    }
  };

  const updateGame = (game) => {
    const findUser = game?.UsersBits?.filter((user) => {
      return user?.userId === userId;
    });
    findUser?.forEach((data) => {
      const { Bit, SelectedFrame } = data;
      const gameIndex = selectedFram?.findIndex(
        (frame) => frame?.selectFrame === SelectedFrame
      );
      if (gameIndex !== -1) {
        selectedFram[gameIndex].Bit += Bit;
      }
    });
  };

  const getAmount = (coin) => {
    let amount = 0;
    if (coin == gameCoin[0]) amount = gameCoin[0];
    else if (coin == gameCoin[1]) amount = gameCoin[1];
    else if (coin == gameCoin[2]) amount = gameCoin[2];
    else if (coin == gameCoin[3]) amount = gameCoin[3];
    else if (coin == gameCoin[4]) amount = gameCoin[4];

    return amount;
  };

  const addBit = (bitcoin, myframe) => {
    let amount = getAmount(bitcoin);
    if (bitcoin != -1 && myframe !== 0) {
      if (userData?.diamond - amount >= 0) {
        userData.diamond -= amount;
        socket?.emit("bit", {
          User: userData,
          Bit: bitcoin,
          SelectedFrame: myframe,
        });

        const selectedFrameIndex = selectedFram?.findIndex(
          (frame) => frame.selectFrame === myframe
        );
        selectedFram[selectedFrameIndex].Bit += bitcoin;
      } else {
        ToastConent(
          "You don't enough diamond now, please  recharge first!",
          "warning"
        );
      }
    } else {
      ToastConent("Missing to select any Coin", "info");
    }
  };

  const handleFramClick = (fram) => {
    if (selectedCoin >= 0) {
      addBit(selectedCoin == 0 ? gameCoin[0] : selectedCoin, fram);
    }
  };
  const betButtonClick1 = (e) => {
    selectedCoin = gameCoin[0];
  };
  const betButtonClick2 = (e) => {
    selectedCoin = gameCoin[1];
  };
  const betButtonClick3 = (e) => {
    selectedCoin = gameCoin[2];
  };
  const betButtonClick4 = (e) => {
    selectedCoin = gameCoin[3];
  };

  return (
    <div className="row">
      <div className="col-12 col-md-3"></div>
      <div className="col-12 col-md-6">
        <div className="show-game">
          <div className="wheelContent">
            <div className="cricle-bg1"></div>
            <div className="roundNumber">
              <div style={{ position: "relative" }}>
                <img src={roundbg} />
                <div style={{ position: "absolute", top: "10px", right: "20px", display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <img src={round}/> <span>{gameRound}</span>
                </div>
              </div>
            </div>
            <div className="top-button">
              <div>
                <button
                  className="rules-btn "
                  onClick={(event) => {
                    handleOpenModel("rules");
                    event.stopPropagation();
                  }}
                >
                  <Options />
                </button>
                <button
                  className="records-btn"
                  onClick={(event) => {
                    handleOpenModel("records");
                    event.stopPropagation();
                  }}
                >
                  <Winner />
                </button>
              </div>
              <div className="coin-display">
                <Rupee />
                {userData?.diamond ? userData?.diamond?.toLocaleString() : 0}
                {/* <Plus /> */}
              </div>
            </div>
            <div className="show-table">
              <div className="cricle-bg2"></div>
              <div className="wheel-text">
                <h5>{`${timerText ? timerText : "0"}`}</h5>
              </div>
              <SelectCricle
                timeText={"5"}
                image={imageShowData[0]}
                cricleMainStlye={{
                  left: "40%",
                  bottom: "95%",
                  transform: "transform: translate(0%, 0%);",
                }}
                selectFrame={selectedFram[0]}
                framNo={1}
                onClick={() => handleFramClick(1)}
              />
              <SelectCricle
                timeText={"5"}
                image={imageShowData[1]}
                cricleMainStlye={{
                  right: "12%",
                  bottom: "96%",
                  transform: "translate(50%, 50%)",
                }}
                framNo={2}
                selectFrame={selectedFram[1]}
                onClick={() => handleFramClick(2)}
              />
              <SelectCricle
                timeText={"10"}
                image={imageShowData[2]}
                cricleMainStlye={{
                  right: "0",
                  bottom: "67%",
                  transform: "translate(50%, 50%)",
                }}
                framNo={3}
                selectFrame={selectedFram[2]}
                onClick={() => handleFramClick(3)}
              />
              <SelectCricle
                timeText={"15"}
                image={imageShowData[3]}
                cricleMainStlye={{
                  right: "11%",
                  bottom: "38%",
                  transform: "translate(50%, 50%)",
                }}
                framNo={4}
                selectFrame={selectedFram[3]}
                onClick={() => handleFramClick(4)}
              />
              <SelectCricle
                timeText={"25"}
                image={imageShowData[4]}
                cricleMainStlye={{
                  right: "47%",
                  bottom: "30%",
                  transform: "translate(50%, 50%)",
                }}
                framNo={5}
                selectFrame={selectedFram[4]}
                onClick={() => handleFramClick(5)}
              />
              <SelectCricle
                timeText={"45"}
                image={imageShowData[5]}
                cricleMainStlye={{
                  right: "83%",
                  bottom: "40%",
                  transform: "translate(50%, 50%)",
                }}
                framNo={6}
                selectFrame={selectedFram[5]}
                onClick={() => handleFramClick(6)}
              />
              <SelectCricle
                timeText={"5"}
                image={imageShowData[6]}
                cricleMainStlye={{
                  right: "95%",
                  bottom: "66%",
                  transform: "translate(50%, 50%)",
                }}
                framNo={7}
                selectFrame={selectedFram[6]}
                onClick={() => handleFramClick(7)}
              />
              <SelectCricle
                timeText={"5"}
                image={imageShowData[7]}
                cricleMainStlye={{
                  right: "84%",
                  bottom: "93%",
                  transform: "translate(50%, 50%)",
                }}
                framNo={8}
                selectFrame={selectedFram[7]}
                onClick={() => handleFramClick(8)}
              />
              {/* {showHandImage && (
                <div className={`hand-content ${handClasses[handClassIndex]}`}>
                  <img src={HandIcon} />
                </div>
              )} */}
              <div className="dimond-select">
                <CoinButton
                amount={settingData?.gameCoin[0] || 100}
                  number={coinShowData[0]}
                  funcClick={betButtonClick1}
                  selectedClass={
                    selectedCoin == gameCoin[0] ? "selectedCoin" : ""
                  }
                  disabledClass={canPressCoins === false ? "coinDisabled" : ""}
                />
                <CoinButton
                  amount={settingData?.gameCoin[1] || 500}
                  number={coinShowData[1]}
                  funcClick={betButtonClick2}
                  selectedClass={
                    selectedCoin == gameCoin[1] ? "selectedCoin" : ""
                  }
                  disabledClass={canPressCoins === false ? "coinDisabled" : ""}
                />
                <CoinButton
                  amount={settingData?.gameCoin[2] || 2000}
                  number={coinShowData[2]}
                  funcClick={betButtonClick3}
                  selectedClass={
                    selectedCoin == gameCoin[2] ? "selectedCoin" : ""
                  }
                  disabledClass={canPressCoins === false ? "coinDisabled" : ""}
                />
                <CoinButton
                  amount={settingData?.gameCoin[3] || 5000}
                  number={coinShowData[3]}
                  funcClick={betButtonClick4}
                  selectedClass={
                    selectedCoin == gameCoin[3] ? "selectedCoin" : ""
                  }
                  disabledClass={canPressCoins === false ? "coinDisabled" : ""}
                />
              </div>
              {/* <div className="balance-profile">
                <div className="balance-box">
                  <h6>Gold balance</h6>
                  <div className="balance-number">
                    <img src={DimondIcon} />
                    <h5>
                      {userData?.diamond
                        ? userData?.diamond?.toLocaleString()
                        : 0}
                    </h5>
                  </div>
                </div>
                <div className="balance-box">
                  <h6>Today's profit</h6>
                  <div className="balance-number">
                    <img src={DimondIcon} />
                    <h5>{todayProfit}</h5>
                  </div>
                </div>
              </div> */}
              <div className="result-content">
                <div style={{ position: "relative" }}>
                  <img src={resultbg} alt="result" style={{ width: "100%" }} />
                  <div className="result-box">
                    <div className="last-reslut">
                      {historiesShow ? (
                        historiesShow?.map((item, index) => {
                          return (
                            <>
                              <div className="gift-reslut">
                                <div className="new-label">
                                  <span>NEW</span>
                                </div>
                                <img src={imageShowData[item - 1]} />
                              </div>
                            </>
                          );
                        })
                      ) : (
                        <h6
                          style={{
                            color: "white",
                            textAlign: "center",
                            width: "100%",
                            marginTop: "5px",
                          }}
                        >
                          Not Result...
                        </h6>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <RecordsDialog
            open={recordsDialogOpen}
            historyRecord={historyRecord}
            setOpen={setRecordsDialogOpen}
          />
        </div>
        <RulesDialog open={rulesDialogOpen} setOpen={setRulesDialogOpen} />
        <WinnerDialog
          open={winnerModelOpen}
          setOpen={setWinnerModelOpen}
          winnerShow={winnerShow}
          currentGame={currentGame}
          userData={userData}
          gameRound={gameRoundWinnerShow}
        />
      </div>
      <div className="col-12 col-md-3">
        <div className="cricle-bg3"></div>
      </div>
    </div>
  );
}
